import React, { useState, Suspense } from "react"
import UnicornImg from "@assets/placeholder/icons/unicorn.svg"
import InputText from "./InputText"
import { CirclePicker } from 'react-color'
import { isEmpty } from "@utils/util"

const InfoInput = (props) => {
  const [selectedValues, setSelectedValue] = useState({ markerName: '', description: '', renderCircle: false, circleRadius: '150', circleColor: '#f44336' });
  const [errorMessages, setErrorMessages] = useState({ markerName: '', description: '', renderCircle: '' })

  const handleInput = (event) => {
    const name = event.name;
    const value = event.value;
    const tmp = Object.assign({}, selectedValues);
    tmp[name] = value;
    setSelectedValue(tmp);
    console.log(tmp);
    validateIndividual(name, value);
  }

  const handleRenderCircleChange = (event) => {
    const checked = event.target.checked;
    const tmp = Object.assign({}, selectedValues);
    tmp.renderCircle = checked;
    setSelectedValue(tmp);
  }

  const handleColorChange = (event) => {
    console.log(event);
    const tmp = Object.assign({}, selectedValues);
    tmp.circleColor = event.hex;
    setSelectedValue(tmp);
  }

  const handleApplyButton = (event) => {
    const isValid = validateAll();
    if (isValid) {
      props.onApply({
        lat: props.lat,
        lng: props.lng,
        markerType: props.markerType,
        markerName: selectedValues.markerName,
        description: selectedValues.description,
        renderCircle: selectedValues.renderCircle,
        circleRadius: parseInt(selectedValues.circleRadius),
        circleColor: selectedValues.circleColor
      })
    }
  }

  const validateAll = () => {
    const validateErrors = ['markerName', 'description', 'circleRadius'].reduce((acc, name) => {
      const value = selectedValues[name];
      const errorMessage = getErrorMessage(name, value);
      if (errorMessage) {
        acc[name] = errorMessage;
      }
      return acc;
    }, {});
    setErrorMessages(validateErrors);
    return Object.keys(validateErrors).length === 0;
  }

  const validateIndividual = (name, value) => {
    const tmp = Object.assign({}, errorMessages);
    delete tmp[name];
    let errorMessage = getErrorMessage(name, value);
    if (errorMessage) {
      tmp[name] = errorMessage;
    }
    console.log(tmp);
    setErrorMessages(tmp);
  }

  const getErrorMessage = (name, value) => {
    if (name === 'markerName' || name === 'description') {
      if (isEmpty(value)) {
        return '値が入力されていません'
      }
    }

    if (name === 'circleRadius') {
      if (selectedValues.renderCircle) {
        if (isEmpty(value)) {
          return '値が入力されていません'
        }
        if (!/^([1-9])(\d+)?$/.test(value)) {
          return '整数で入力してください'
        }
      }
    }
    return null;
  }

  return (
    <div className="container">

      <section className="section" style={{padding: "10px 0"}}>
        <div className="container has-text-centered">
          <h2 className="title">マーカーの情報を入力してください</h2>
          <p>この画面で設定した情報は、マーカーがクリックされた際に表示されます。</p>
        </div>
      </section>
                
      <section className="section is-paddingless">
        <div className="container">
          <div className="block">
            <div className="columns is-mobile is-centered">
              <InputText name="markerName"
                        placeholder="マーカー名"
                        selectedValue={selectedValues.markerName}
                        required={true}
                        errorMessage={errorMessages.markerName}
                        onInput={handleInput} />
            </div>
            <div className="columns is-mobile is-centered">
              <InputText name="description"
                        placeholder="説明文"
                        selectedValue={selectedValues.description}
                        required={true}
                        errorMessage={errorMessages.description}
                        onInput={handleInput} />
            </div>
            <div className="columns is-mobile is-centered">
              <div className="column field is-four-fifths">
                <input id="renderCircle" type="checkbox" name="renderCircle" className="switch is-rounded" onChange={handleRenderCircleChange} />
                <label htmlFor="renderCircle">範囲円を描画する</label>
              </div>
            </div>
            {
              selectedValues.renderCircle && 
              <div>
                <div className="columns is-mobile is-centered">
                  <InputText name="circleRadius"
                            placeholder="メートル数を入力"
                            selectedValue={selectedValues.circleRadius}
                            required={true}
                            errorMessage={errorMessages.circleRadius}
                            onInput={handleInput} />
                </div>
                <div className="columns is-mobile is-centered">
                  <div className="column field is-four-fifths">
                    <CirclePicker onChange={handleColorChange} color={selectedValues.circleColor} />
                  </div>
                </div>
              </div>
            }
          </div>
          <div className="buttons is-centered has-margin-bottom-30">
            <button className="button is-medium is-outlined" onClick={() => props.onCancel()}>キャンセル</button>
            <button className="button is-medium is-danger is-outlined has-margin-left-30 has-padding-left-50 has-padding-right-50" onClick={handleApplyButton}>決定</button>
          </div>
        </div>
      </section>
    </div>
  )
}

export default InfoInput;
