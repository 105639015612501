import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'

/**
 * props
 * name: name属性名
 * placeholder: placeholder文言
 * required: (true or false)
 */
const InputText = (props) => {
  const questionName = props.name;
  const placeholder = props.placeholder;
  const required = props.required;
  const selectedValue = props.selectedValue;
  const errorMessage = props.errorMessage;

  const handleInputText = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    props.onInput({name, value});
  }

  const id = questionName;
  const element = <div className="field" key={id}>
  <input className="input" type="text" id={id}
                                       name={questionName}
                                       placeholder={placeholder}
                                       onChange={handleInputText}
                                       defaultValue={selectedValue} />
  </div>

  return (
    <div className="column field is-four-fifths">
      {element}
      
      {
        errorMessage && <div className="field has-text-danger">
        <FontAwesomeIcon icon={faExclamationCircle} /> { errorMessage }
        </div>
      }
    </div>
  );
}

export default InputText;