import React, { useState, useEffect } from "react"
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  Circle,
  InfoWindow,
} from "react-google-maps";

const MarkerWithInfo = (props) => {
  const marker = props.marker;
  const [infoWindow, setInfoWindow] = useState({'markerId': { open: false, marker: null }})

  const handleMarkerClick = (marker) => {
    console.log(marker, 'maaaaaaa');
    const tmp = Object.assign({}, infoWindow);
    tmp[marker.id] = { open: true, marker };
    setInfoWindow(tmp);
  }

  const handleCloseInfoWindow = (marker) => {
    console.log('close close');
    const tmp = Object.assign({}, infoWindow);
    tmp[marker.id] = { open: false, marker };
    setInfoWindow(tmp);
  }

  return (
    <Marker
      icon={marker.icon}
      anchor={marker.anchor}
      position={marker.position}
      onClick={() => handleMarkerClick(marker)}
    >
      {
        infoWindow[marker.id] && infoWindow[marker.id].open &&
        <InfoWindow onCloseClick={() => handleCloseInfoWindow(marker)}>
          <div>
            <div>{marker.name}</div>
            <div>{marker.description}</div>
            <div className="has-margin-top-10">
              <button className="button is-small" onClick={() => props.onDeleteMarker(marker)}>削除</button>
            </div>
          </div>
        </InfoWindow>
      }
    </Marker>
  );
}

export default MarkerWithInfo;